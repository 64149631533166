/* Globale Stile */
body {
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
}

/* Container */
.posts-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

/* Post Container */
.post-container {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s;
}

.post-container:hover {
    transform: scale(1.02);
}

/* Image Container */
.image-container {
    width: 100%;
    height: 200px;
    overflow: hidden;
    position: relative;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s;
}

.post-container:hover .image {
    transform: scale(1.1);
}

/* Content */
.content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}

/* Title */
.title {
    font-size: 1.5em;
    color: #333;
    margin-bottom: 10px;
}

/* Body */
.body {
    color: #666;
    line-height: 1.6;
    margin-bottom: 20px;
    flex-grow: 1;
}

/* Author */
.author {
    font-size: 0.9em;
    color: #999;
    margin-bottom: 20px;
}

/* Read More Link */
.read-more {
    display: inline-block;
    padding: 10px 20px;
    background-color: #0073e6;
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
    align-self: flex-end;
    margin-top: 20px;
}

.read-more:hover {
    background-color: #005bb5;
}

/* Responsive Design */
@media (max-width: 600px) {
    .title {
        font-size: 1.2em;
    }

    .image-container {
        height: 150px;
    }
}
