.custom-list-decimal {
    list-style-type: none;
    counter-reset: my-awesome-counter;
    padding-left: 0;
  }
  
  .custom-list-decimal > li {
    position: relative;
    padding-left: 40px; /* Platz für den Kreis */
    counter-increment: my-awesome-counter;
  }
  
  .custom-list-decimal > li::before {
    content: counter(my-awesome-counter);
    position: absolute;
    left: 0;
    top: 0;
    padding-right: 4px;
    width: 30px;
    height: 30px;
    background-color: rgb(95, 135, 255);
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
  
  .border-l-4 {
    border-left-width: 4px;
  }
  
  .border-blue-500 {
    border-left-color: #3b82f6;
  }
  
  /* Styles for the responsive images */
/* Styles for the responsive images */
.responsive-img {
  width: 100%;
}

@media (min-width: 768px) {
  .responsive-img {
    width: 20%; /* Adjust the percentage to your needs */
    margin-left: auto;
    height: auto;
    margin-right: auto;
    display: block;
  }
}
